import React, { useEffect, useRef, useState } from "react";
import { graphql } from 'gatsby';
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../components/Layout/Layout';
import HomeHead from '../sections/HomeHead/HomeHead';
import ShadowContainer from '../components/ShadowContainer/ShadowContainer';
import Blocks from '../sections/Blocks/Blocks';
import { selectTag } from '../actions';


const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 0;
`

const Inner = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
`

const Spacer = styled.div`
  width: 100%;
  height: ${props => props.height}px;
  pointer-events: none;
`

const Scroll = styled.div`
position: relative;
width: 100%;
z-index: 0;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
`
const Dummy = styled.div.attrs((props) => ({
  style: {
    height: `${props.height+20}px`,
    clipPath: `polygon(0% 0%,100% 0%,100% ${props.height+20}px,99.2% ${props.height+10}px,98.5% ${props.height+6}px,1.5% ${props.height+6}px,0.8% ${props.height+10}px,0% ${props.height+20}px)`
  }
}))`position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 2;
  overflow: hidden;
`

const ShadowMask = styled(ShadowContainer)`
  position: fixed;
  top: ${props => props.height}px;
  width: 100%;
  z-index: 3; 
`

const Block = styled.div`
  background-color: #FAFAFA;
  padding-bottom: 100%;
`
const blockArray = [...Array(30)];
// markup
const ScrollPage = ({data}) => {

  const [ GridPos, setGridPos] = useState(800);
  const gridEl = useRef();
  const shadowEl = useRef();
  const dummyEl = useRef();

  const utilRedux = useSelector(state => state.utilReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectTag(null));
    /*dummyEl.current.style.position = 'fixed';
    dummyEl.current.style.left = '0';
    dummyEl.current.style.top = '0';
    dummyEl.current.style.width = '100%';
    dummyEl.current.style.backgroundColor = 'white';
    dummyEl.current.style.zIndex = '2';
    dummyEl.current.style.overflow = 'hidden';*/


    window.addEventListener('scroll', onScroll);
  },[]);

  const onScroll = (e) => {
    //console.log(e);

    let gridTop = gridEl.current.getBoundingClientRect().top;
    if(gridTop < 100) { 
      gridTop = 100;
    }

    setGridPos(gridTop);

    shadowEl.current.style.position = 'fixed';
    shadowEl.current.style.width = '100%';
    shadowEl.current.style.zIndex = '3';
    shadowEl.current.style.top = `${gridTop}px`;

    //dummyEl.current.style.height = `${gridTop}px`;
    //dummyEl.current.style.clipPath = `polygon(0% 0%,100% 0%,100% ${gridTop+20}px,99.2% ${gridTop+10}px,98.5% ${gridTop+6}px,1.5% ${gridTop+6}px,0.8% ${gridTop+10}px,0% ${gridTop+20}px)`



    /*if(gridTop >= 100) {
      setGridPos(gridTop);
    } else {
      setGridPos(100);
    }*/
    
    //console.log(gridEl.current.getBoundingClientRect());
  }

  if (!data) return null

  const document = data.allPrismicMyrchBlock.edges;

  return (
    <Layout>
      <Dummy height={GridPos}>
        <HomeHead/>
      </Dummy>
      <div ref={shadowEl}>
      <ShadowContainer></ShadowContainer>
      </div>
      <Scroll>
        <Spacer height={utilRedux && utilRedux.spaceHeight ? utilRedux.spaceHeight : '600'}/>
        <div ref={gridEl}>
          <Blocks blocks={document}/>
        </div>
      </Scroll>
    </Layout>
  )
}

export const query = graphql`
  query ScrollQuery {
    allPrismicMyrchBlock {
      edges {
        node {
          uid
          data {
            image {
              alt
              copyright
              url
              thumbnails
              dimensions {
                height
                width
              }
            }
            image_hover {
              alt
              copyright
              url
              thumbnails
            }
            logo {
              alt
              copyright
              url
              thumbnails
            }
            product_text
            url {
              url
            }
            category_text
          }
        }
      }
    }
  }
`

export default ScrollPage
